import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import QueryString from 'querystringify';
import { Storage } from '@ionic/storage';

import { appsettings } from '../conf';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient, private storage: Storage) { }

    //*** OLD
    /*getAuth(): Observable<any> {
        return this.http.post<any>(`${appsettings.API.DOMAIN}${appsettings.API.AUTH}`,
            null,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                tap(response => { }),
                catchError(this.handleError<any>('Get auth', null))
            );
    }*/

    token(deviceId): Observable<any> {
        return this.http.post<any>(`${appsettings.API.DOMAIN}${appsettings.API.TOKEN}`,
            QueryString.stringify({ appKey: appsettings.API.APPKEY, device: deviceId }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                tap(response => { }),
                catchError(this.handleError<any>('Get token', null))
            );
    }

    retrieve(token, deviceId): Observable<any> {
        return this.http.post<any>(`${appsettings.API.DOMAIN}${appsettings.API.RETRIEVE}`,
            QueryString.stringify({ appKey: appsettings.API.APPKEY, token: token, device: deviceId }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                tap(response => { }),
                catchError(this.handleError<any>('Get retrieve', null))
            );
    }

    unlock(phone, deviceId): Observable<any> {
        return this.http.post<any>(`${appsettings.API.DOMAIN}${appsettings.API.UNLOCK}`,
            QueryString.stringify({ appKey: appsettings.API.APPKEY, device: deviceId, phone: phone }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                tap(response => { }),
                catchError(this.handleError<any>('Get retrieve', null))
            );
    }

    storeAuth(token, device) {
        this.storage.set('token', token);
        appsettings.TOKEN = token;
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
import { Component } from '@angular/core';

import { Platform, ToastController, LoadingController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import * as jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import { EventService } from './services/event.service';

import { QuizzService } from './services/quizz.service';
import { AuthService } from './services/auth.service';

import { appsettings } from './conf';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    loader: any;

    constructor(
        private platform: Platform,
        public toastController: ToastController,
        public loadingController: LoadingController,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private storage: Storage,
        private quizzService: QuizzService,
        private authService: AuthService,
        private router: Router,
        private eventService: EventService,
        private menu: MenuController
    ) {
        this.initializeApp();
    }

    initializeApp() {
        let self = this;

        this.platform.ready().then(() => {
            self.statusBar.styleDefault();
            self.splashScreen.hide();

            //*** OLD
            /*let authOk = ((appKey) => {
                appsettings.API.APPKEY = appKey;
                self.lazyLoading();
            });

            self.storage.get('appKey').then((k) => {
                if (k !== null)
                    authOk(k);
                else {
                    self.authService.getAuth().subscribe((response) => {
                        if (response !== undefined && response !== null && response.response === 'success') {
                            self.storage.set('roleId', response.role_id);
                            self.storage.set('appKey', response.app_key);
                            authOk(response.app_key);
                        }
                    });
                }
            });*/

            let reset = () => {
                appsettings.VERSION.RESET.forEach((r) => {
                    self.storage.remove(r);
                });

                self.storage.set('version', appsettings.VERSION.NUM);
                self.auth();
            };

            self.storage.get('version').then((v) => {
                if (v !== null) {
                    if (v === appsettings.VERSION.NUM)
                        self.auth();
                    else
                        reset();
                } else
                    reset();
            });
            
        });
    }

    auth() {
        let self = this;

        //*** AUTH HACK
        //this.storage.set('token', '7556ab61-3d33-4bf7-9cd7-d48b44a663e7');
        //this.storage.set('device', '3fb00d75-0f7b-4554-95fa-8590beb3dc16');

        let go = function (t, d) {
            self.authService.storeAuth(t, d);
            self.quizzService.lazyLoading();
        };

        let retrieve = function (token) {
            self.storage.get('device').then((d) => {
                if (d !== null) {
                    appsettings.DEVICE = d;
                    self.authService.retrieve(token, d).subscribe((resp: any) => {
                        if (resp !== null && resp.response === 'success')
                            go(token, d);
                        else
                            init();
                    }, (error: any) => {
                        init();
                    });
                } else
                    init();
            });
        };

        let check = function (deviceId) {
            appsettings.DEVICE = deviceId;
            self.authService.token(deviceId).subscribe((resp: any) => {
                if (resp !== null && resp.response === 'success') {
                    self.storage.set('token', resp.token);
                    go(resp.token, deviceId);
                } else
                    init();
            }, (error: any) => {
                init();
            });
        };

        let init = function () {
            self.storage.remove('token');
            self.storage.remove('device');

            /*let deviceId = this.deviceManager.uuid;
                if (deviceId === null)
                    deviceId = uuidv4();*/
            let deviceId = uuidv4();

            self.storage.set('device', deviceId);
            appsettings.DEVICE = deviceId;

            if (!appsettings.AUTH.IN)
                window.location.href = `${appsettings.OUT.AUTH}/${deviceId}`;
        };

        this.storage.get('token').then((t) => {
            if (t !== null)
                retrieve(t);
            else {
                self.storage.get('device').then((d) => {
                    if (d !== null)
                        check(d);
                    else
                        init();
                });
            }

        });
    }

    async toogleLoader(show, duration = 8000, text = null) {
        if (show) {
            if (this.loader === undefined) {
                this.loader = await this.loadingController.create({
                    message: (text === null ? 'Veuillez patienter...' : text),
                    duration: duration
                });
                await this.loader.present();

                await this.loader.onDidDismiss();
                this.loader = undefined;
            }
        } else {
            if (this.loader !== undefined)
                this.loader.dismiss().catch(() => { });
        }
    }

    async workSaved(empty) {
        const toast = await this.toastController.create({
            message: (!empty ? 'Vos réponses ont bien été sauvegardées.' : 'Aucune réponse n\'a été renseignée'),
            duration: 2000
        });
        toast.present();
    }

    save(loader = 0, callback = null) {
        this.menu.close('topbar');

        let self = this;
        this.storage.get('history').then((h) => {
            if (h !== null) {
                self.storage.set('work', h);
                self.storage.get('session').then((t) => {
                    if (t !== null) {
                        if (loader > 0)
                            self.toogleLoader(true, loader);

                        self.quizzService.save(h, t).subscribe(() => {
                            if (callback === null)
                                self.workSaved(false);
                            else
                                callback();
                        });
                    }
                });
            } else
                self.workSaved(true);
        });
    }

    pdf() {
        this.menu.close('topbar');

        let self = this, work, cnt, cb, temp = [], temp2, temp3, temp4, doc, quizzs, splitQuizz, wordWrap, addHomeContent, addProgressContent, addRoomContent, save, pxTomm, addIndex = false, rightQuizz = null, leftHead = null, rightHead = null, rightBorder = null, map = null, check = null, foot, cnt2, y, x, color1, color2;

        this.save(30000, () => {
            self.storage.get('work').then((w) => {
                work = JSON.parse(w);

                cnt = appsettings.MAP.ROOMS.length + 1;

                pxTomm = ((px) => {
                    return (px / (96 / 72)) / (72 / 25.4);
                });

                splitQuizz = ((quizz) => {
                    var i, j, temparray = [], chunk = 6;
                    for (i = 0, j = quizz.length; i < j; i += chunk) {
                        temparray.push(quizz.slice(i, i + chunk));
                    }
                    return temparray;
                });

                wordWrap = ((str, maxWidth) => {
                    str = str.replace(/\n/g, '');

                    let testWhite = function (x) {
                        var white = new RegExp(/^\s$/);
                        return white.test(x.charAt(0));
                    };

                    let newLineStr = "\n", done = false, res = '';
                    while (str.length > maxWidth) {
                        done = false;
                        // Inserts new line at first whitespace of the line
                        for (let i = maxWidth - 1; i >= 0; i--) {
                            if (testWhite(str.charAt(i))) {
                                res = res + [str.slice(0, i), newLineStr].join('');
                                str = str.slice(i + 1);
                                done = true;
                                break;
                            }
                        }
                        // Inserts new line at maxWidth position, the word is too long to wrap
                        if (!done) {
                            res += [str.slice(0, maxWidth), newLineStr].join('');
                            str = str.slice(maxWidth);
                        }

                    }

                    return res + str;
                });

                addHomeContent = (() => {
                    let img = new Image();
                    img.src = appsettings.MAP.PDF.HOME;
                    img.onload = function () {
                        doc.addImage(img, 'JPEG', 190, 90, 527, 414);
                        addProgressContent();
                    }
                });

                addProgressContent = (() => {
                    doc.addPage();

                    temp3 = [];
                    appsettings.MAP.ROOMS.forEach((r) => {
                        temp3 = temp3.concat(r.quizzs);
                    });
                    temp4 = self.quizzService.calcProgress(temp3, appsettings.MAP.PROGRESSES);


                    let percent, level;
                    let max = temp4.percent0;
                    level = 0;
                    if (max < temp4.percent1) {
                        max = temp4.percent1;
                        level = 1;
                    } if (max < temp4.percent2) {
                        max = temp4.percent2;
                        level = 2;
                    }

                    map = new Image();

                    if (temp4.rule !== null) {
                        map.src = appsettings.MAP.PDF[`LEVEL${temp4.rule.level}`].img;
                        color1 = appsettings.MAP.PDF[`LEVEL${temp4.rule.level}`].color1;
                        color2 = appsettings.MAP.PDF[`LEVEL${temp4.rule.level}`].color2;
                        percent = appsettings.MAP.PDF[`LEVEL${temp4.rule.level}`].title.replace('###_VALUE_###', max);
                    } else {
                        map.src = appsettings.MAP.PDF[`LEVEL${level}`].img;
                        color1 = appsettings.MAP.PDF[`LEVEL${level}`].color1;
                        color2 = appsettings.MAP.PDF[`LEVEL${level}`].color2;
                        percent = appsettings.MAP.PDF[`LEVEL${level}`].title.replace('###_VALUE_###', max);
                    }

                    map.onload = function () {
                        doc.addImage(map, 'JPEG', 250, 20, 368, 250);

                        doc.setTextColor(color2);
                        doc.setFontStyle('bold');
                        doc.setFontSize(30);
                        doc.text(450, 130, percent.toUpperCase(), { align: 'center' });

                        let y = 310;
                        let nl, v;

                        if (temp4.rule !== null) {
                            v = wordWrap(temp4.rule.stage, 70)
                            doc.setTextColor(color1);
                            doc.setFontStyle('bold');
                            doc.setFontSize(15);
                            doc.text(250, y, v, { align: 'left' });
                            nl = (v.match(/\n/g) || []).length;

                            y += 40 + (15 * nl);

                            v = wordWrap(temp4.rule.feedback, 70);
                            doc.setTextColor("#666666");
                            doc.setFontStyle('normal');
                            doc.setFontSize(15);
                            doc.text(250, y, v, { align: 'left' });
                            nl = (v.match(/\n/g) || []).length;

                            y += 40 + (15 * nl);

                            v = wordWrap(temp4.rule.boost, 70);
                            doc.setTextColor("#666666");
                            doc.setFontStyle('bold');
                            doc.setFontSize(15);
                            doc.text(250, y, v, { align: 'left' });
                            nl = (v.match(/\n/g) || []).length;

                            y += 40 + (15 * nl);

                            doc.setTextColor("#5567ae");
                            doc.setFontStyle('bold');
                            doc.setFontSize(15);
                            doc.text(250, y, wordWrap(temp4.rule.help, 70), { align: 'left' });
                        }

                        addRoomContent(0);
                    };
                });

                addRoomContent = ((index) => {
                    cnt2 = 6;

                    let addLayout = ((r) => {
                        cnt2 -= 1;
                        if (cnt2 === 0)
                            addQuizzTextContent(r);
                    });

                    let addQuizzTextContent = ((r) => {
                        quizzs = splitQuizz(r.quizzs);
                        if (quizzs.length > 0) {
                            addIndex = quizzs.length > 1;

                            quizzs.forEach((q1, i) => {
                                doc.addPage();

                                if(leftHead !== null)
                                    doc.addImage(leftHead, 'JPEG', 20, 0, 549, 160);

                                doc.setTextColor('#666666');
                                doc.setFontStyle('normal');
                                doc.setFontSize(15);
                                    
                                y = 148;
                                q1.forEach((q2) => {
                                    y += 39;
                                    x = -1;
                                    doc.text(105, y, wordWrap(q2.label, 88), { align: 'left' });

                                    if (rightQuizz !== null)
                                        doc.addImage(rightQuizz, 'JPEG', 585, (y - 15), 276, 24);

                                    if (q2.values !== null && q2.values.length > 0) {
                                        if (q2.values[0])
                                            x = 595;
                                        else if (q2.values[1])
                                            x = 658;
                                        else if (q2.values[2])
                                            x = 721;
                                        doc.addImage(check, 'JPEG', x, (y - 14), 22, 22);
                                    }
                                });

                                if (rightHead !== null)
                                    doc.addImage(rightHead, 'JPEG', 585, 0, 276, 174);

                                if (rightBorder !== null)
                                    doc.addImage(rightBorder, 'JPEG', 776, 150, 5, 350);

                                if (foot !== null)
                                    doc.addImage(foot, 'JPEG', 20, 405, 841, 226);

                                doc.setTextColor('#ffffff');
                                doc.setFontStyle('bold');
                                doc.setFontSize(24);
                                doc.text(258, 102, `${r.title.toUpperCase()}${addIndex ? ' (' + (i + 1) + ')' : ''}`, { align: 'center' });

                                if (r.baseline !== null) {
                                    doc.setTextColor(r.color);
                                    doc.setFontStyle('normal');
                                    doc.setFontSize(20);
                                    doc.text(258, 132, r.baseline, { align: 'center' });
                                }
                            });
                        }

                        addRoomContent((index + 1));
                    });

                    if (index < appsettings.MAP.ROOMS.length) {

                        let r = appsettings.MAP.ROOMS.sort((a, b) => { return a.sort - b.sort; })[index];

                        if (r.pdf[0] !== null) {
                            leftHead = new Image();
                            leftHead.src = r.pdf[0];
                            leftHead.onload = function () {
                                addLayout(r);
                            };
                        } else {
                            leftHead = null;
                            addLayout(r);
                        }

                        if (rightHead === null) {
                            rightHead = new Image();
                            rightHead.src = 'assets/pdf/head_right.png';
                            rightHead.onload = function () {
                                addLayout(r);
                            };
                        } else 
                            addLayout(r);

                        if (rightBorder === null) {
                            rightBorder = new Image();
                            rightBorder.src = 'assets/pdf/border_right.png';
                            rightBorder.onload = function () {
                                addLayout(r);
                            };
                        } else
                            addLayout(r);

                        if (rightQuizz === null) {
                            rightQuizz = new Image();
                            rightQuizz.src = 'assets/pdf/quizz_right.png';
                            rightQuizz.onload = function () {
                                addLayout(r);
                            };
                        } else
                            addLayout(r);

                        if (check === null) {
                            check = new Image();
                            check.src = 'assets/pdf/check.png';
                            check.onload = function () {
                                addLayout(r);
                            };
                        } else
                            addLayout(r);

                        if (r.pdf[1] !== null) {
                            foot = new Image();
                            foot.src = r.pdf[1];
                            foot.onload = function () {
                                addLayout(r);
                            };
                        } else {
                            foot = null;
                            addLayout(r);
                        }

                    } else
                        save();
                });

                save = (() => {
                    doc.save(`auto_evaluation_reamenages_${new Date().getTime()}.pdf`);

                    self.toogleLoader(false);
                });

                cb = (() => {
                    cnt -= 1;
                    if (cnt === 0) {
                        if (temp.length > 0) {
                            appsettings.MAP.ROOMS = appsettings.MAP.ROOMS.map((r) => {
                                temp2 = temp.find((t) => {
                                    return t.id === r.id;
                                });
                                if (temp2 !== undefined)
                                    r.quizzs = temp2.quizzs;
                                return r;
                            });
                        }

                        doc = new jsPDF({
                            orientation: 'l',
                            unit: 'px',
                            format: 'a3',
                            putOnlyUsedFonts: true,
                            compress: true
                        });

                        addHomeContent();
                    }
                });

                appsettings.MAP.ROOMS.forEach((r) => {
                    if (r.quizzs.length === 0) {
                        self.quizzService.getQuizzs(r).subscribe((response) => {
                            temp.push({ id: r.id, quizzs: self.quizzService.prepareQuizzs(response, work) });
                            cb();
                        });
                    } else
                        cb();
                });

                if (appsettings.MAP.PROGRESSES.length === 0) {
                    self.quizzService.getProgresses().subscribe((response) => {
                        appsettings.MAP.PROGRESSES = response;
                        cb();
                    });
                } else
                    cb();
            });
        });
    }

    isPartner() {
        //return appsettings.API.APPKEY !== null; OLD
        return true;
    }

    home() {
        this.menu.close('topbar');
        this.router.navigate(['/']);
    }

    backRoom() {
        if (!(appsettings.AUTH.IN && appsettings.TOKEN === null)) {
            this.menu.close('topbar');
            this.router.navigate(['/room']);
            this.eventService.publishEvent({ key: 'back-room', data: null });
        }
    }

    isMobile() {
        return this.platform.width() < appsettings.MOBILE_MAX_WITH;
    }
}

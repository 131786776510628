import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import QueryString from 'querystringify';

import { appsettings } from '../conf';
import { Quizz } from './../class/quizz';
import { Progress } from './../class/progress';

@Injectable({
    providedIn: 'root'
})

export class QuizzService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient) { }

    getQuizzs(room): Observable<Quizz[]> {
        return this.http.post<Quizz[]>(`${appsettings.API.DOMAIN}${appsettings.API.QUIZZS}`,
            //QueryString.stringify({ appKey: appsettings.API.APPKEY, room: room.id }), OLD
            QueryString.stringify({ appKey: appsettings.API.APPKEY, token: appsettings.TOKEN, device: appsettings.DEVICE, room: room.id }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                tap((quizzs) => { }),
                catchError(this.handleError<Quizz[]>('Get Quizzs', []))
            );
    }

    save(work, session): Observable<any> {
        return this.http.post<any>(`${appsettings.API.DOMAIN}${appsettings.API.SAVE}`,
            //QueryString.stringify({ appKey: appsettings.API.APPKEY, work: work, session: session }), OLD
            QueryString.stringify({ appKey: appsettings.API.APPKEY, token: appsettings.TOKEN, device: appsettings.DEVICE, work: work, session: session }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                tap(response => { }),
                catchError(this.handleError<any>('Save work', null))
            );
    }

    getProgresses(): Observable<Progress[]> {
        return this.http.post<Progress[]>(`${appsettings.API.DOMAIN}${appsettings.API.PROGRESSES}`,
            //QueryString.stringify({ appKey: appsettings.API.APPKEY }), OLD
            QueryString.stringify({ appKey: appsettings.API.APPKEY, token: appsettings.TOKEN, device: appsettings.DEVICE }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                tap((progresses) => { }),
                catchError(this.handleError<Progress[]>('Get Progresses', []))
            );
    }

    lazyLoading() {
        let self = this, temp, temp2 = [];

        let cnt = appsettings.LAZY.ROOMS.length;
        let cb = (() => {
            cnt -= 1;
            if (cnt === 0) {
                if (temp2.length > 0) {
                    appsettings.LAZY.ROOMS = appsettings.LAZY.ROOMS.map((r) => {
                        temp = temp2.find((t) => {
                            return t.id === r.id;
                        });
                        if (temp !== undefined)
                            r.quizzs = temp.quizzs;
                        return r;
                    });
                }
            }
        });

        if (appsettings.LAZY.ACTIVE) {

            appsettings.LAZY.ROOMS.forEach((r1) => {
                temp = appsettings.MAP.ROOMS.find((r2) => {
                    return r1.id === r2.id && r2.quizzs.length === 0;
                });
                if (temp !== undefined) {
                    self.getQuizzs(r1).subscribe((response) => {
                        temp2.push({ id: r1.id, quizzs: response });
                        cb();
                    });
                } else
                    cb();
            });

            this.getProgresses().subscribe((response) => {
                appsettings.LAZY.PROGRESSES = response;
            });

        }
    }

    prepareQuizzs(serverResponse, work) {
        let temp;

        return serverResponse.map((q: Quizz) => {
            q.colors = [(q.choice1_level === 0 ? 'success' : 'danger'), 'yellow', (q.choice3_level === 0 ? 'success' : 'danger')];
            q.values = [false, false, false];

            if (work !== null) {
                temp = work.find((h) => {
                    return h.id === q.id;
                });
                if (temp !== undefined)
                    q.values[temp.index] = true;
            }

            return q;
        }).sort((a: Quizz, b: Quizz) => {
            return (a.order - b.order);
        });
    }

    calcProgress(quizzs, progresses) {
        let level0 = 0, percent0 = 0, level1 = 0, percent1 = 0, level2 = 0, percent2 = 0, tot = 0, index, empty = true, rooms = [];

        quizzs.forEach((q) => {
            index = null;
            if (q.values[0])
                index = 0;
            else if (q.values[1])
                index = 1;
            else if (q.values[2])
                index = 2;
            if (index !== null) {
                if (rooms.indexOf(q.room_id) === -1)
                    rooms.push(q.room_id);
                empty = false;
                if (q[`choice${(index + 1)}_level`] === 0)
                    level0 += 1;
                else if (q[`choice${(index + 1)}_level`] === 1)
                    level1 += 1;
                else
                    level2 += 1;
                tot += 1;
            }
        });

        percent0 = Math.round((level0 * 100 / tot) * 100) / 100;
        percent1 = Math.round((level1 * 100 / tot) * 100) / 100;
        percent2 = Math.round((level2 * 100 / tot) * 100) / 100;

        let rule: Progress = null;
        if (!empty && progresses.length > 0) {
            progresses.sort((a: Progress, b: Progress) => {
                return (a.order - b.order);
            }).forEach((p) => {
                if (rule === null && p.level === 0 && p.start <= percent0 && p.end >= percent0)
                    rule = p;
                if (rule === null && p.level === 1 && p.start <= percent1 && p.end >= percent1)
                    rule = p;
                if (rule === null && p.level === 2 && p.start <= percent2 && p.end >= percent2)
                    rule = p;
            });
        }

        return {
            percent0: percent0,
            percent1: percent1,
            percent2: percent2,
            rule: rule,
            empty: empty,
            rooms: rooms
        };
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
export let appsettings: any = {
    VERSION: {
        NUM: '1.0.2',
        RESET: ['token', 'device'],
    },
    STORAGE_NAME: '__reamenages',
    AUTH: {
        IN: true,
        OUT: 'https://ollwin.divercities-access.com/session/app/authentication/5'
    },
    API: {
        DOMAIN: 'https://api.reamenages.fr/',
        //APPKEY: null, OLD
        APPKEY: 'LmL=336.Ef{r',
        AUTH: 'REAMENAGES/get_auth.php',
        TOKEN: 'REAMENAGES/get_access_token.php',
        RETRIEVE: 'REAMENAGES/retrieve_access_token.php',
        UNLOCK: 'REAMENAGES/unlock.php',
        QUIZZS: 'REAMENAGES/get_quizzs.php',
        SAVE: 'REAMENAGES/save.php',
        PROGRESSES: 'REAMENAGES/get_progresses.php',
    },
    TOKEN: null,
    DEVICE: null,
    MAP: {
        LOADED: false,
        ROOMS: [
            { id: 1, label: 'Cuisine', title: 'La cuisine', baseline: null, icon: 'assets/icon/cuisine.png', pdf: ['assets/pdf/head_cuisine.png', 'assets/pdf/foot_cuisine.png'], color: '#ec9010', quizzs: [], sort: 0 },
            { id: 2, label: 'Pièce à vivre', title: 'Pièces à vivre', baseline: 'Salon, salle-à-manger, bureau', icon: 'assets/icon/piece_a_vivre.png', pdf: ['assets/pdf/head_piece-a-vivre.png', 'assets/pdf/foot_piece-a-vivre.png'], color: '#5568af', quizzs: [], sort: 3 },
            { id: 3, label: 'Autres pièces', title: 'Autres pièces', baseline: 'Couloirs, escaliers, garage, accès...', icon: null, pdf: ['assets/pdf/head_autres-pieces.png', 'assets/pdf/foot_autres-pieces.png'], color: '#7c2c62', quizzs: [], sort: 5 },
            { id: 4, label: 'Chambre', title: 'La chambre', baseline: null, icon: 'assets/icon/chambre.png', pdf: ['assets/pdf/head_chambre.png', 'assets/pdf/foot_chambre.png'], color: '#df477b', quizzs: [], sort: 1 },
            { id: 5, label: 'Salle d\'eau', title: 'La salle d\'eau', baseline: null, icon: 'assets/icon/salle_eau.png', pdf: ['assets/pdf/head_salle-eau.png', 'assets/pdf/foot_salle-eau.png'], color: '#35a5dd', quizzs: [], sort: 2 },
            { id: 6, label: 'Toilettes', title: 'Les toilettes', baseline: null, icon: 'assets/icon/toilettes.png', pdf: ['assets/pdf/head_toilettes.png', 'assets/pdf/foot_toilettes.png'], color: '#8abc5e', quizzs: [], sort: 4 }
        ],
        PROGRESSES: [],
		PDF: {
            HOME: 'assets/pdf/home.png',
            LEVEL0: { color1: '#80bd55', color2: '#ffffff', img: 'assets/pdf/map_green.png', title: '###_VALUE_###%\nde vert' },
            LEVEL1: { color1: '#efc51b', color2: '#bc9b12', img: 'assets/pdf/map_yellow.png', title: '###_VALUE_###%\nde jaune' },
            LEVEL2: { color1: '#c55753', color2: '#ffffff', img: 'assets/pdf/map_red.png', title: '###_VALUE_###%\nde rouge' }
		}
    },
    SLIDES: 7,
    HEADERS: {
        DEFAULT: {
            LOGO: 'assets/logo/reamenages-logo.png',
            CSS: 'header-default'
        },
        HOME_SLIDES_1: {
            LOGO: 'assets/logo/reamenages-logo.png',
            CSS: 'header-home-slide1'
        },
        HOME_SLIDES_2: {
            LOGO: 'assets/logo/reamenages-logo.png',
            CSS: 'header-home-slide2'
        },
        HOME_SLIDES_3: {
            LOGO: 'assets/logo/reamenages-logo-bleu-blanc.png',
            CSS: 'header-home-slide3'
        },
        HOME_SLIDES_4: {
            //LOGO: 'assets/logo/reamenages-logo-bleu.png',
            LOGO: 'assets/logo/reamenages-logo-bleu-blanc.png',
            CSS: 'header-home-slide4'
        },
        HOME_SLIDES_5: {
            LOGO: 'assets/logo/reamenages-logo.png',
            CSS: 'header-home-slide5'
        },
        HOME_SLIDES_6: {
            LOGO: 'assets/logo/reamenages-logo.png',
            CSS: 'header-home-slide6'
        },
        HOME_SLIDES_7: {
            LOGO: 'assets/logo/reamenages-logo.png',
            CSS: 'header-home-slide7'
        },
        MAP: {
            LOGO: 'assets/logo/reamenages-diagnostic-logo.png',
            CSS: 'header-map'
        },
        DIAG: {
            LOGO: 'assets/logo/reamenages-evaluation-logo.png',
            CSS: 'header-diag'
        },
        PROGRESSES: {
            DEFAULT: {
                LOGO: 'assets/logo/reamenages-resultats-global-logo.png',
                CSS: 'header-progress',
                ICON: 'logo-global'
            },
            ROOM_1: { // Cuisine
                LOGO: 'assets/logo/reamenages-resultats-cuisine-logo.png',
                CSS: 'header-progress',
                ICON: 'logo-cuisine'
            },
            ROOM_2: { // Pièce à vivre
                LOGO: 'assets/logo/reamenages-resultats-pieces-vivre-logo.png',
                CSS: 'header-progress',
                ICON: 'logo-pieces-vivre'
            },
            ROOM_3: { // Autres pièces
                LOGO: 'assets/logo/reamenages-resultats-autre-logo.png',
                CSS: 'header-progress',
                ICON: 'logo-autre'
            },
            ROOM_4: { // Chambre
                LOGO: 'assets/logo/reamenages-resultats-chambre-logo.png',
                CSS: 'header-progress',
                ICON: 'logo-chambre'
            },
            ROOM_5: { // Salle d\'eau
                LOGO: 'assets/logo/reamenages-resultats-salle-eau-logo.png',
                CSS: 'header-progress',
                ICON: 'logo-salle-eau'
            },
            ROOM_6: { // Toilettes
                LOGO: 'assets/logo/reamenages-resultats-toilettes-logo.png',
                CSS: 'header-progress',
                ICON: 'logo-toilettes'
            }
        }
    },
    LAZY: {
        ACTIVE: true,
        ROOMS: [
            { id: 1, quizzs: [] },
            { id: 2, quizzs: [] },
            { id: 3, quizzs: [] },
            { id: 4, quizzs: [] },
            { id: 5, quizzs: [] },
            { id: 6, quizzs: [] }
        ],
        PROGRESSES: []
    },
    MOBILE_MAX_WITH: 992
};

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../class/message';

@Injectable({
    providedIn: 'root'
})

export class EventService {
    private eventAnnouncedSource = new Subject();
    eventSource$ = this.eventAnnouncedSource.asObservable();

    constructor() { }

    publishEvent(data: Message) {
        this.eventAnnouncedSource.next(data)
    }
}